import React, { useState, useEffect, useRef } from 'react';
import logo from './images/AI3-192.png';
import maeAssistLogo from './images/mae_assist.jpg'
import { FaCartPlus } from "react-icons/fa6";
import { FormGroup, FormControlLabel, Switch, Typography, Collapse } from '@mui/material';
import { Rating } from 'semantic-ui-react';
import { FaUsers } from "react-icons/fa6";
import { FaTools } from "react-icons/fa";
import './Marketplace.css';
import useStore from '../hooks/useStore';
import companyLogo from './images/TD SYNNEX_Logo_Aqua-White.png';

const products = [];

function MarketplaceAssistantCard({ assistant, isActive, onAssistantSubscribe }) {
  const [promptExpanded, setPromptExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const promptRef = useRef(null);

  const handleAssistantSubscribe = () => {
    onAssistantSubscribe(assistant.id, !isActive);
  };

  const handleExpandClick = () => {
    setPromptExpanded(!promptExpanded);
  };

  useEffect(() => {
    const promptElement = promptRef.current;
    if (promptElement) {
      const isOverflowing = promptElement.scrollHeight > promptElement.clientHeight;
      setShowExpandButton(isOverflowing);
    }
  }, [assistant.systemPrompt]);

  return (
    <div key={assistant.id} className="assistant-card">
      <div className="assistant-card-name font-bold text-lg truncate">
        {assistant.name}
      </div>
      <div className="assistant-card-body">
        <div
          ref={promptRef}
          className={`assistant-system-prompt ${promptExpanded ? 'expanded' : 'collapsed'}`}
        >
          {assistant.systemPrompt}
        </div>
        {showExpandButton && (
          <Typography variant="body2" color="primary" onClick={handleExpandClick} style={{ cursor: 'pointer' }}>
            {promptExpanded ? 'Show less' : 'Show more'}
          </Typography>
        )}
        <div className="users-count"><FaUsers />&nbsp;{`${assistant.shares.marketplaceSharesCount}`}</div>

        {assistant.tools.length > 0 ? (
          <div className="tools-list">
            <FaTools />&nbsp;{assistant.tools?.join(", ")}
          </div>
        ) : ``}

        <div className="assistant-card-owners">
          <div className="assistant-card-owners-title">Owners</div>
          {/* display a comma-separated list of owners */}
          <span className="assistant-card-owner">
            {assistant.owners.join(', ')}
          </span>
        </div>
        <div className="assistant-card-ratings">
          <FormGroup>
            <div><Rating maxRating={5} defaultRating={4} icon='star' /></div>
            <FormControlLabel control={<Switch checked={isActive} onChange={handleAssistantSubscribe} />} label="Subscribe" />
          </FormGroup>
        </div>
      </div>
    </div>
  );
}

export function Marketplace({ onClose, onProductBuy, activeProducts, marketplaceAssistants, activeAssistants, onAssistantSubscribe }) {
  const [show, setShow] = useState(false);
  const [activeProductsSet, setActiveProductsSet] = useState(new Set(activeProducts));
  const [activeAssistantsSet, setActiveAssistantsSet] = useState(new Set(activeAssistants));
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAssistants, setFilteredAssistants] = useState(marketplaceAssistants);
  const [totalAssistants, setTotalAssistants] = useState(0);
  const appName = useStore((state) => state.appName);

  useEffect(() => {
    setTotalAssistants(marketplaceAssistants.length);
  }, [marketplaceAssistants]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const filtered = marketplaceAssistants.filter((assistant) =>
      assistant.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      assistant.systemPrompt.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredAssistants(filtered);
  }, [marketplaceAssistants, searchQuery]);

  const handleClose = () => {
    setShow(false);
    setTimeout(onClose, 500);
  };

  const handleBuyClick = (productId, isBought) => {
    if (isBought) {
      const newSet = new Set(activeProductsSet);
      newSet.delete(productId);
      setActiveProductsSet(newSet);
      onProductBuy(productId, false);
    } else {
      const newSet = new Set(activeProductsSet);
      newSet.add(productId);
      setActiveProductsSet(newSet);
      onProductBuy(productId, true);
    }
  };

  return (
    <div className={`marketplace ${show ? 'show' : 'hide'}`}>
      <div className="top-tdsynnex-bar">
        <div className="company-logo-landing">
          <img src={companyLogo} alt="TD SYNNEX logo" style={{width:'auto', height:'30px'}} />
        </div>
      </div>
      <div className="marketplace-header">
        <div className="marketplace-title">
          <img src={appName === "Mae Assist" ? maeAssistLogo : logo} alt="GenAI logo" className="marketplace-logo" />
          <p >GenAI Marketplace <FaCartPlus size={14} /></p>
        </div>

        <div className="search-container">
          <input
            id="search"
            type="text"
            placeholder="Search by assistant name or description"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-box"
          />
        </div>
        <div className="close-button-container">
          <button onClick={handleClose}>Back to {appName}</button>
        </div>
      </div>

      <div className="marketplace-body">
        <div className="marketplace-assistants">
          <h3 className="marketplace-assistants-title">Available Assistants ({totalAssistants})</h3>
          <div className="marketplace-assistants-list">
            {filteredAssistants.map(assistant => (
              <MarketplaceAssistantCard assistant={assistant} isActive={activeAssistants.includes(assistant.id)} onAssistantSubscribe={onAssistantSubscribe} />
            ))}
          </div>
        </div>
        <div className="cards">
          {products.map(product => (
            <div key={product.id} className="product-card border border-gray-200 rounded-lg overflow-hidden p-6">
              <img src={product.image} alt={product.name} className="product-card-image rounded-full" />
              <div className="product-card-name font-bold text-lg truncate">
                {product.name}
              </div>
              <div className="product-card-type">{product.type}</div>
              <div className="product-card-description">{product.description}</div>
              <div className="product-card-details">
                {activeProductsSet.has(product.id) ? <p className="active-product-message">This product is active</p> : ``}
                <div className="product-card-cost">{product.subscriptionCost.cost} {product.subscriptionCost.costCurrency} per {product.subscriptionCost.costFrequency}</div>
                <button
                  className={`${activeProductsSet.has(product.id) ? 'cancel-product-button' : 'buy-button'}`}
                  onClick={() => handleBuyClick(product.id, activeProductsSet.has(product.id))}
                >
                  {activeProductsSet.has(product.id) ? 'Cancel' : 'Activate'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
