// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-spinner {
  width: 1.2rem; /* Equivalent to size-4 in Tailwind */
  height: 1.2rem; /* Equivalent to size-4 in Tailwind */
  animation: spin 1s linear infinite; /* Equivalent to animate-spin in Tailwind */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.icon-spinner2 {
  display: inline-block;
  width: 1.2rem; /* Equivalent to size-4 in Tailwind */
  height: 1.2rem; /* Equivalent to size-4 in Tailwind */
  border: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  /* border-top-color: #000; */
  animation: spin 1s linear infinite;
}

`, "",{"version":3,"sources":["webpack://./src/components/UI/Icons.css"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE,qCAAqC;EACpD,cAAc,EAAE,qCAAqC;EACrD,kCAAkC,EAAE,2CAA2C;AACjF;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;;AAGA;EACE,qBAAqB;EACrB,aAAa,EAAE,qCAAqC;EACpD,cAAc,EAAE,qCAAqC;EACrD,oCAAoC;EACpC,kBAAkB;EAClB,4BAA4B;EAC5B,kCAAkC;AACpC","sourcesContent":[".icon-spinner {\n  width: 1.2rem; /* Equivalent to size-4 in Tailwind */\n  height: 1.2rem; /* Equivalent to size-4 in Tailwind */\n  animation: spin 1s linear infinite; /* Equivalent to animate-spin in Tailwind */\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n\n.icon-spinner2 {\n  display: inline-block;\n  width: 1.2rem; /* Equivalent to size-4 in Tailwind */\n  height: 1.2rem; /* Equivalent to size-4 in Tailwind */\n  border: 0px solid rgba(0, 0, 0, 0.1);\n  border-radius: 50%;\n  /* border-top-color: #000; */\n  animation: spin 1s linear infinite;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
