import React, { useState, useEffect } from 'react';
import './AiAssistantKnowledgeManager.css';
import { 
  Button, Container, Form, 
  Grid, Header, Input, TextArea,
  Select, Confirm, Checkbox,
} from 'semantic-ui-react';
import { set } from 'react-hook-form';


export function AiAssistantKnowledgeManager({
  assistantId,
  socket,
  onFilesRefresh, 
  isOwner
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileChunksCount, setSelectedFileChunksCount] = useState(0);
  const [filesList, setFilesList] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredFilesList, setFilteredFilesList] = useState([]);

  useEffect(() => {
    const filteredFiles = filesList.filter((file) =>
      file.dataSource.toLowerCase().includes(searchQuery.toLowerCase()) ||
      file.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
      file.originalFilename.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredFilesList(filteredFiles);
  }, [filesList, searchQuery]);
  
  useEffect(() => {
    socket.emit('get-assistant-kb-metadata', { assistantId });
    // console.log({ assistantId });
  }, [assistantId]);

  useEffect(() => {
    socket.on('assistant-kb-metadata', (blobsMetadata) => {
      setFilesList(blobsMetadata.map(file => {
        
        if (file.dataSource === undefined) {
          file.dataSource = (
            file.dataPipeline !== undefined ? `IT-led ingestion (${file.dataPipeline})` : "Manual upload"
            )
        }
        return file;
      }));
      onFilesRefresh(blobsMetadata.length);
    },);

    socket.on("assistant-kb-file-chunks-count", ({filename,count}) => {
      console.log(`Setting chunksCount for ${filename} to ${count}`)
      setSelectedFileChunksCount(count);
    });

    

    return () => {
      socket.off('knowledge-files');
      socket.off('assistant-kb-file-chunks-count');
    }
  }, []);

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setShowEditForm(true);
    handleFilenameChunksCount(file.filename);
    // add an ESC key handler to close the form
    

  };

  // ESC key handler while showEditForm is true 
  useEffect(() => {
    const escHandler = (e) => {
      if (e.keyCode === 27) {
        setShowEditForm(false);
      }
    };
    if (showEditForm) {
      window.addEventListener('keydown', escHandler);
    }
    return () => {
      window.removeEventListener('keydown', escHandler);
    }
  }, [showEditForm]);



  const handleDeleteOpenConfirmPopup = (e, file) => {
    //disable other click handlers
    e.stopPropagation();
    setFileToDelete(file);
    setOpenConfirmDelete(true);
  };

  const handleDeleteFile = () => {
    const file = fileToDelete;
    deleteKnowledgeHandler(file);
    // remove from list
    setFilesList(filesList.filter((f) => f.filename !== file.filename));
    setSelectedFile(null);
    setOpenConfirmDelete(false);
    
  };

  const handleDeleteOrphanedBlob = (blob) => {
    deleteOrphanedBlobHandler(blob);
  };

  const handleDeleteOrphanedIndexes = (indexes) => {
    deleteOrphanedIndexesHandler(indexes);
  };


  const handleCancelDelete = () => {
    setOpenConfirmDelete(false);
  }

  const handleCancelEditForm = () => {
    setShowEditForm(false);
  }

  const handleSubmitAndReindex = (e) => {
    e.preventDefault();
    console.log('submit and reindex');
  }

  const handleFilenameChunksCount = (filename) => {
    // console.log(`Filename: ${filename}`);
    socket.emit('get-assistant-kb-file-chunks-count', {assistantId, filename});
  }

  const handleFileUpload = (e) => {
    e.preventDefault();
    const file = e.target.file.files[0];
    const subject = e.target.subject.value;
    const learnMoreLinks = e.target.learnMoreLinks.value;
    const category = e.target.category.value;
    const data = new FormData();
    data.append('file', file);
    data.append('subject', subject);
    data.append('learnMoreLinks', learnMoreLinks);
    data.append('category', category);
    socket.emit('upload-assistant-kb-file', {assistantId, data});
  }

  const deleteKnowledgeHandler = (file) => {
    // Handle delete file logic here
    console.log("Deleting file:", file)
    socket.emit('delete-knowledge-file', file.id, assistantId)
  }

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };
  


  return (

    <div className="knowledge-manager">
      <div className="km-body">


        <div className="knowledge-and-orphaned-tables-container">
          <Form>
            <Form.Field>
              <Input
                type="text"
                placeholder="Search by subject or original filename"
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </Form.Field>
          </Form>
          {/* <div className="km-subtitle">Knowledge Data (click on a row to see details)</div> */}
          <div className="knowledge-main-table-container">
            
            { filesList.length > 0 ? (
              <table className="knowledge-table">
                <thead>
                  <tr>
                    {/* <th>Category</th> */}
                    <th>Data Source</th>
                    <th>Subject</th>
                    <th>Original Filename</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredFilesList.map((file, index) => (
                    <tr 
                      key={file.filename} 
                      className={selectedFile === file ? 'selected' : ''}
                      
                    >
                      {/* <td className="category">{file.category}</td> */}
                      <td className="datasource">{file.dataSource !== undefined ? file.dataSource : (
                        file.dataPipeline !== undefined ? `IT-led ingestion (${file.dataPipeline})` : "Manual upload"
                        )}
                      
                      </td>
                      <td className="subject">{file.subject}</td>
                      <td className="filename">{file.originalFilename}</td>
                      <td>
                        <div className = "km-buttons-container">
                          <Button className="delete-button" type="button" disabled={!isOwner} onClick={(e) => handleDeleteOpenConfirmPopup(e, file)}>
                            Delete
                          </Button>
                          <Button type="button" color="blue" onClick={() => handleFileClick(file)}>Details</Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="no-knowledge-data">
                <p>
                  No knowledge data found.
                </p>
                
                <p>
                  You can upload files manually or use Manage SharePoint Data Sources to create a Data Ingestion Plan, and then use Ingest SharePoint Files whenever you wish to refresh the knowledge base. Just use the buttons below.
                </p>
              </div>
            )}
            
          </div>
            <div className="confirm-popup-container">
            <Confirm
                header={"Delete File"}
                content={`You are about to delete file ${fileToDelete?.filename}. Are you sure?`}
                open={openConfirmDelete}
                onCancel={handleCancelDelete}
                onConfirm={handleDeleteFile}
                cancelButton="Do not delete"
                confirmButton="Yes, delete it"
            />
          </div>
          <div className="km-footer">Files Count: {filesList.length}</div>
          {/* <Button type="button" onClick={setShowUploadForm}>Upload File</Button> */}
          {/* { showUploadForm && <UploadFileForm onFileSubmit={handleFileUpload} onCancel={() => setShowUploadForm(false)} /> } */}
          
          
        </div>
        {selectedFile && showEditForm && (
        <div className="overlay" tabIndex="-1">  
          <div className="modal-popup">
            
              {/* semanti-ui form */}
              <Container> 
                    <Header as="h3" textAlign="center" className="kb-file-edit-header">
                      <div>Edit File Details</div>        
                    </Header>
                    <>
                      <div className = "km-details-title">{selectedFile.originalFilename}</div>
                      <p><strong>Category:</strong> {selectedFile.category}</p>
                      <p><strong>Subject:</strong> {selectedFile.subject}</p>
                      {selectedFile.learnMoreLinks !== undefined && selectedFile.learnMoreLinks !== "undefined" && (
                        <p>
                          <strong>Learn More Links:</strong>{' '}
                          <a href={selectedFile.learnMoreLinks} target="_blank" rel="noreferrer">{selectedFile.learnMoreLinks}</a>
                          
                        </p>
                      )}
                      <p><strong>Azure Blob:</strong> {selectedFile.filename}</p>
                      <p><strong>Count of index entries for this blob:</strong> {selectedFileChunksCount}</p>
                    </>
                    <Form onSubmit={handleSubmitAndReindex} >
                  {/* show all file subkeys */}
                    {Object.keys(selectedFile).map((key) => (
                      key === 'subject' && (
                        <Form.Field
                          key={key}
                          control={Input}
                          label={key}
                          value={selectedFile[key]}
                          
                        />
                      )))}
                    {Object.keys(selectedFile).map((key) => (
                      !["filename", "category", "originalFilename", "username"].includes(key) && key !== 'subject' && (
                        <Form.Field
                          key={key}
                          control={Input}
                          label={key}
                          value={selectedFile[key]}
                        />
                      )
                    ))}

                    <div className='kb-file-buttons'>
                      <Button primary type="submit">Apply & Reindex</Button>
                      <Button type="button" 
                        onClick={handleCancelEditForm}
                        color="dark brown">Cancel</Button>
                    </div>  
                </Form> 
              </Container>
            
          </div>
        </div>
        )}
      </div>    

    </div>
  );
}

export default AiAssistantKnowledgeManager;
