import React from "react";
import ReactDOM from "react-dom/client";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";

import { setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import { msalInstance } from './msalInstance';

setChonkyDefaults({
  iconComponent: ChonkyIconFA,
  disableDragAndDrop: true, // Disable drag and drop. I had to do this to avoid a duplicate HTML5 provider error in the app.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
