// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    background-color: #3498db;
    color: #fff;
    transition: all 0.25s ease-in-out;
  }
  
  button:hover {
    background-color: #2980b9;
  }
  
  button:focus {
    outline: none;
  }

  button:active {
    background-color: #5895be;
  }
  
  button.green {
    background-color: #2ecc71;
  }
  
  button.green:hover {
    background-color: #27ae60;
  }

  button.purple {
    background-color: #9b59b6;
  }

  button.purple:hover {
    background-color: #8e44ad;
  }

.toggle-button-area {
  position: relative;
}

.toggle-button__model_details {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 20em;
  min-height: 4em;
  padding: 10px;
  background-color: #ffcc00;
  color: #000;
  border-radius: 5px;
  transition: all 0.25s ease-in-out;
  font-size: small;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  visibility: hidden;
}

button:hover + .toggle-button__model_details {
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/components/ToggleButton.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;AAEF;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,SAAS;EACT,8BAA8B;EAC9B,WAAW;EACX,eAAe;EACf,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,iCAAiC;EACjC,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["button {\n    font-family: Arial, Helvetica, sans-serif;\n    font-size: 16px;\n    border: none;\n    border-radius: 5px;\n    padding: 10px 20px;\n    margin: 10px;\n    cursor: pointer;\n    background-color: #3498db;\n    color: #fff;\n    transition: all 0.25s ease-in-out;\n  }\n  \n  button:hover {\n    background-color: #2980b9;\n  }\n  \n  button:focus {\n    outline: none;\n  }\n\n  button:active {\n    background-color: #5895be;\n  }\n  \n  button.green {\n    background-color: #2ecc71;\n  }\n  \n  button.green:hover {\n    background-color: #27ae60;\n  }\n\n  button.purple {\n    background-color: #9b59b6;\n  }\n\n  button.purple:hover {\n    background-color: #8e44ad;\n  }\n\n.toggle-button-area {\n  position: relative;\n}\n\n.toggle-button__model_details {\n  position: absolute;\n  z-index: 2;\n  top: 100%;\n  left: 50%;\n  transform: translate(-50%, 0%);\n  width: 20em;\n  min-height: 4em;\n  padding: 10px;\n  background-color: #ffcc00;\n  color: #000;\n  border-radius: 5px;\n  transition: all 0.25s ease-in-out;\n  font-size: small;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  text-align: left;\n  visibility: hidden;\n}\n\nbutton:hover + .toggle-button__model_details {\n  visibility: visible;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
