import axios from 'axios';

import { msalInstance } from '../msalInstance';
import { webAPIRequest } from "../authConfig";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  async (config) => {
    try {
      const accounts = msalInstance.getAllAccounts();      
      if (accounts.length == 0) {
        console.warn('Nenhuma conta ativa encontrada.');
        return config;
      }

      const response = await msalInstance.acquireTokenSilent({
        ...webAPIRequest,
        account: accounts[0],
      });

      config.headers.Authorization = `Bearer ${response.accessToken}`;
    } catch (error) {
      console.error('Erro ao obter o token:', error);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;