// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExternalAccessSection_api-keys-list__YCMgP {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.ExternalAccessSection_api-keys-item__FKJDa {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.ExternalAccessSection_api-keys-input__PQ7cO {
  max-width: 150px;
  display: flex;
  flex-wrap: nowrap;
}
.ExternalAccessSection_api-keys-copy-button__cBazw {
  position: relative;
  left: 70px;
}

.ExternalAccessSection_external-access-list__ML\\+1h {
  display: flex;
  flex-direction: column;
}

.ExternalAccessSection_external-access-item__Bze\\+Q {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.ExternalAccessSection_external-access-field__l-in5 {
  flex: 1 1;
  margin: 0 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AiAssistant/ExternalAccessSection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,SAAO;EACP,cAAc;AAChB","sourcesContent":[".api-keys-list {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  flex-wrap: wrap;\n  margin-bottom: 10px;\n}\n\n.api-keys-item {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 10px;\n}\n\n.api-keys-input {\n  max-width: 150px;\n  display: flex;\n  flex-wrap: nowrap;\n}\n.api-keys-copy-button {\n  position: relative;\n  left: 70px;\n}\n\n.external-access-list {\n  display: flex;\n  flex-direction: column;\n}\n\n.external-access-item {\n  display: flex;\n  justify-content: space-between;\n  padding: 10px;\n  border-bottom: 1px solid #ccc;\n}\n\n.external-access-field {\n  flex: 1;\n  margin: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"api-keys-list": `ExternalAccessSection_api-keys-list__YCMgP`,
	"api-keys-item": `ExternalAccessSection_api-keys-item__FKJDa`,
	"api-keys-input": `ExternalAccessSection_api-keys-input__PQ7cO`,
	"api-keys-copy-button": `ExternalAccessSection_api-keys-copy-button__cBazw`,
	"external-access-list": `ExternalAccessSection_external-access-list__ML+1h`,
	"external-access-item": `ExternalAccessSection_external-access-item__Bze+Q`,
	"external-access-field": `ExternalAccessSection_external-access-field__l-in5`
};
export default ___CSS_LOADER_EXPORT___;
