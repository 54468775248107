import { Badge } from '@mui/material';
import { IconButton } from '@mui/material';
import { IoMdClose as CloseIcon } from "react-icons/io";

const ImageFile = ({ image, handleRemove, sx = {} }) => {
  return (
    <Badge
      sx={sx}
      badgeContent={
        <IconButton
          size="small"
          onClick={handleRemove}
          sx={{
            width: '20px',
            height: '20px',
            position: 'absolute',
            top: '3px',
            right: '2px',
            bgcolor: 'white',
            borderRadius: '50%',
            '&:hover': {
              bgcolor: 'lightgray',
            },
          }}
        >
          <CloseIcon sx={{ backgroundColor: 'red' }} />
        </IconButton>
      }
      overlap="circular"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <img src={image.url} className="mini-image" alt="Pasted Image" />
    </Badge>
  )
}

export default ImageFile