import React, { useState, useRef, useEffect } from 'react';
import './Username.css';
import CommandsForm from './CommandsForm';
import { Menu, MenuItem, Button } from '@mui/material';
import useStore from '../hooks/useStore';

let commandsJsonData = (appName) => ({
  "commands": [
      {
        "name": "/az-devops-pat",
        "description": "Update your Azure DevOps Personal Access Token",
        "parameters": [
            {
                "name": "PAT",
                "type": "string",
                "description": `Inform your new Azure Devops 
                Personal Access Token to allow
                ${appName} to ingest data from
                Azure DevOps, such as Wikis.
                This parameter is not visible after 
                submission for security reasons.`,
                "required": true,
            },
          ]
      }
  ]
})


function PatPopup({ updatePat, commandParametersHandler, commandFormKeyDownHandler, appName}) {
  return (
    <div className="pat-popup">
      <h3>Update Personal Access Token</h3>
      <CommandsForm
        commandJSON={commandsJsonData(appName).commands[0]} 
        commandParametersHandler={commandParametersHandler} commandFormKeyDownHandler={commandFormKeyDownHandler} 
      />
    </div>
  );
}

function UserMenu({ username, onSetPAT }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetPAT = () => {
    // Logic to set Azure DevOps Wiki PAT
    console.log('Set Azure DevOps Wiki PAT clicked');
    handleClose();
    onSetPAT();
  };

  return (
    <div>
      <Button className="username-button" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {username}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSetPAT}>Set Azure DevOps Wiki PAT</MenuItem>
      </Menu>
    </div>
  );
}

function Username({username, photo, onAzureDevOpsUserPAT}) {
  const containerRef = useRef(null);
  const [showPatPopup, setShowPatPopup] = useState(false);
  const appName = useStore((state) => state.appName);

  // use the username initials as a fallback if the photo is not available
  if (!photo && username) {
    const initialsArray = username.split(' ').map(name => name[0].toUpperCase());
    let initials = initialsArray.join('');
    if (initials.length > 2) {
      initials = initials[0] + initials[1];
    }
    photo = `https://ui-avatars.com/api/?name=${initials}&background=random`;
  }


  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setShowPatPopup(false);
      }
    };

    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowPatPopup(false);
      }
    };

    window.addEventListener("keydown", handleEsc);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("keydown", handleEsc);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);


  const handleSetPAT = () => {
    setShowPatPopup(true);
  };

  //handle ESC key
  const commandFormKeyDownHandler = (event) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      setShowPatPopup(false);
    }
  }

  const commandParametersHandler = (data) => {
    setShowPatPopup(false);
    onAzureDevOpsUserPAT(data.parameters[0].value);
  }

  return (
    <div className="username-container">
      <div className="user-photo">
        <img src={photo} alt="User Avatar" />
      </div>
      {/* <h5>{username}</h5> */}
      <UserMenu username={username} onSetPAT={handleSetPAT}/>
      {showPatPopup && <CommandsForm ref={containerRef}
        commandJSON={commandsJsonData(appName).commands[0]} 
        commandParametersHandler={commandParametersHandler} commandFormKeyDownHandler={commandFormKeyDownHandler} 
      />}
    </div>
  );
}

export default Username; 
export { PatPopup };
