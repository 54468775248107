import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

/**
 * @param open boolean
 * @param title modal title
 * @param children modal content
 * @param actions array with all actions that modal footer will display
 * @param size modal size type string and supports mini, tiny, small, large, fullscreen
 */

const GenericModal = ({ open, onClose, title, children, actions, size }) => {
  return (
    <Modal open={open} onClose={onClose} closeOnDimmerClick={false} size={size || 'large'}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        {children}
      </Modal.Content>
      <Modal.Actions>
        {actions ? (
          actions.map((action, index) => (
            <Button 
              key={index} 
              onClick={action.onClick} 
              color={action.color}
            >
              {action.label}
            </Button>
          ))
        ) : (
          <Button onClick={onClose}>Close</Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default GenericModal

