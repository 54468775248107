import { create } from 'zustand'

const useStore = create((set) => {
  const loadPermissions = async () => {
    try {
      const response = await fetch('/mae-assist-config.json')
      if (!response.ok) {
        throw new Error(`Failed while trying to read permission json: ${response.status}`)
      }
      const data = await response.json()
      return data.permissionList
    } catch (error) {
      console.error('Failed while loading permission file', error)
    }
  }

  return {
    appName: "",
    setAppName: (appName) => set(() => ({ appName })),
    loadPermissions
  }
})

export default useStore