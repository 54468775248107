import { useState, useEffect, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import Markdown from 'react-markdown';
import { FcAssistant } from "react-icons/fc";
import companyLogo from './images/TD SYNNEX_Logo_Aqua-White.png';

import { Dropdown } from 'semantic-ui-react';
import './AiAssistantDropdown.css';

function AssistantButton({ assistant, onAssistantSelect }) {
    return (
        <div className="assistant-button-container">
            <div className="assistant-button" onClick={() => onAssistantSelect(assistant)}>
                <div className="assistant-name">{assistant.name}</div>
            </div>
        </div>
    );
}

export function AiAssistantDropdown({ darkMode, aiAssistant, aiAssistants, onAiAssistantSelect, last10AssistantsArray, showLabel = true }) {
    const [selectedAiAssistant, setSelectedAiAssistant] = useState(aiAssistant);
    const assistantRef = useRef(null);
    const last10AssistantsRef = useRef(null);
    const tooltipText = `Select an AI Assistant to determine the AI's behavior. \n\n ## ${aiAssistant?.name || "None"}\n\n**Definition:** ${aiAssistant?.systemPrompt}\n\n ${aiAssistant?.reminder ? `**Reminder:** ${aiAssistant.reminder}` : ''}\n\n${aiAssistant?.tools?.length > 0 ? `**Tools:** ${aiAssistant.tools.join(', ')}` : ''}`;
    const [assistantOptions, setAssistantOptions] = useState([]);
    const [last10Assistants, setLast10Assistants] = useState([]);

    useEffect(() => {
        const options = aiAssistants.map(assistant => {
            return {
                key: assistant.id,
                value: assistant.id,
                text: `${assistant.isGlobal ? "🌐" : ""}${assistant.shares?.marketplace ? "🛒" : ""}${assistant.shares?.AADGroups?.length > 0 ? "👥" : ""}${assistant.shares?.users?.length > 0 ? "👤" : ""} ${assistant.name}`
            };
        });
        setAssistantOptions(options);
    }, [aiAssistants]);

    useEffect(() => {
        const lastTen = [...new Map(last10AssistantsArray.map(item => [item.id, item])).values()];
        setLast10Assistants(lastTen.slice(0, 8));
    }, [last10AssistantsArray]);

    useEffect(() => {
        setSelectedAiAssistant(aiAssistant);
    }, [aiAssistant]);

    const handleChange = (event, data) => {
        console.log('data: ', data);
        const id = data.value;
        const selectedAssistant = aiAssistants.find(assistant => assistant.id === id);
        setSelectedAiAssistant(selectedAssistant);
        console.dir(selectedAssistant);
        onAiAssistantSelect(selectedAssistant);

        setTimeout(() => {
            document.getElementById("message-input-textarea").focus();
        }, 500);
    };

    return (
        <div className="toolbar-contents">
            <div className="prompt-icon-container">
                <div ref={assistantRef} className={`prompt-dropdown-container ${darkMode ? 'dark-mode' : ''}`}>
                    <img src={companyLogo} alt="TD SYNNEX logo" style={{width:'auto', height:'30px'}} />
                    <div className={`ai-assistant-select ${selectedAiAssistant?.id ? "has-value" : ""}`}>
                        <div className="ai-assistant-label">Select an AI Assistant</div>
                        <Dropdown
                            id="assistant-dropdown"
                            className="prompt-dropdown"
                            placeholder="Select AI Assistant"
                            value={selectedAiAssistant?.id || ""}
                            options={assistantOptions}
                            onChange={handleChange}
                            selection
                            search
                            loading={assistantOptions.length === 0}
                            scrolling
                        />
                    </div>
                    {window.innerWidth > 768 && (
                        <Tooltip anchorSelect=".prompt-dropdown" place="right" className="custom-tooltip" noArrow>
                            <Markdown>{tooltipText}</Markdown>
                        </Tooltip>
                    )}
                </div>
            </div>
            {last10Assistants.length > 0 && (
                <div ref={last10AssistantsRef} className="last-ten-assistants">
                    {last10Assistants.map(assistant => (
                        assistant.id !== undefined && assistant.id !== selectedAiAssistant.id && (
                            <AssistantButton key={assistant.id} assistant={assistant} onAssistantSelect={onAiAssistantSelect} />
                        )
                    ))}

                </div>
            )}
        </div>
    );
}
