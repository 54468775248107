/**
 * Return the future date based on the months to be added provided
 * @param {number} months - number of months to be added 
 * @returns {Date} - response with months increased with yyyy-mm-dd format
 */
const getFutureDate = (months) => {
  const today = new Date()
  const futureDate = new Date(today)
  futureDate.setMonth(today.getMonth() + months)
  return futureDate.toISOString().split('T')[0]
}

export { getFutureDate }