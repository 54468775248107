import { useState, useEffect } from 'react';
import './ConversationStarters.css';

export function ConversationStarters({ onConversationStarterClick, darkMode, assistantConversationStarters }) {
  const [conversationStarters, setConversationStarters] = useState([]);

  useEffect(() => {
    setConversationStarters(assistantConversationStarters);
  }, [assistantConversationStarters]);

  return (
    <>
      {conversationStarters && conversationStarters.length > 0 &&
        <div id="conversation-starters" className="conversation-starters">
          {conversationStarters.map((conversationStarter, index) => (
            <div
              key={index}
              className={
                `conversation-starter 
                ${darkMode ? 'dark-mode' : ''}`
              }
              onClick={() => onConversationStarterClick(conversationStarter)}>
              {conversationStarter.title ? conversationStarter.title : conversationStarter.text}
            </div>
          ))}
        </div>
      }
    </>
  );
}
